import i18n from "./plugins/vue-i18n";
const dictionariesService = {
  
  getClassWorkPerWeekHoursList() {
    let classWorkPerWeekHoursList = [];

    for (let i = 1; i<20; i++ ){
      classWorkPerWeekHoursList.push({text: i + " ч.", value: i});
    }
    
    return classWorkPerWeekHoursList;
  },

  getDayOptions() {
    let ret = [];
    
    ret.push({text: i18n.t("timeTable.day.monday"), value: 1});
    ret.push({text: i18n.t("timeTable.day.tuesday"), value: 2});
    ret.push({text: i18n.t("timeTable.day.wednesday"), value: 3});
    ret.push({text: i18n.t("timeTable.day.thursday"), value: 4});
    ret.push({text: i18n.t("timeTable.day.friday"), value: 5});
    ret.push({text: i18n.t("timeTable.day.saturday"), value: 6});
    
    return ret;
  }, 
  
  sexOptions() {
    let ret = [];
    
    ret.push({text: i18n.t("common.sex.male"), value: 2});
    ret.push({text: i18n.t("common.sex.female"), value: 1});
    
    return ret;
  },

  getDayItemOptions() {
    let ret = [];

    for (let i = 0; i<=11; i++ ){
      ret.push({text: i, value: i});
    }

    return ret;
  },  
  getDayItemOptionsShort() {
    let ret = [];

    for (let i = 0; i<=6; i++ ){
      ret.push({text: i, value: i});
    }

    return ret;
  },
  
  getMarksForLesson(){
    let ret = [
      {value: 0, text: ""},
      {value: 1, text: "н"},
      {value: 2, text: "2"},
      {value: 3, text: "3"},
      {value: 4, text: "4"},
      {value: 5, text: "5"},
      {value: 6, text: "зачет"},
    ];
    
    return ret;
  },
  
  displayMark(mark){
    let marks = this.getMarksForLesson();
    for (let i in marks){
      let item = marks[i];
      if(item.value === mark){
        return  item.text
      }
    }
    
    return '';
  },
  getMarksForQuarter(){
    let ret = [
      {value: 0, text: ""},
      {value: 2, text: "2"},
      {value: 3, text: "3"},
      {value: 4, text: "4"},
      {value: 5, text: "5"},
      {value: 6, text: "зачет"},
      {value: 7, text: "незачет"},
      {value: 8, text: "н/а"},
      {value: 9, text: "освобожден"}
    ];

    return ret;
  },
  getMarksForPhysicalActivity(){
    let ret = [
      {value: 0, text: ""},
      {value: 2, text: "2"},
      {value: 3, text: "3"},
      {value: 4, text: "4"},
      {value: 5, text: "5"},
      {value: 8, text: "н/а"},
      {value: 9, text: "освобожден"}
    ];

    return ret;
  },

  displayQuarterMark(mark){
    let marks = this.getMarksForQuarter();
    for (let i in marks){
      let item = marks[i];
      if(item.value === mark){
        return  item.text
      }
    }

    return '';
  },
  
  levelOptions(){
    return ['', 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
  },
  currentYear(){
    return 2024;
  },
  
  eduYears: [{text: "2024-2025", value: 2024}, {text: "2023-2024", value: 2023}, {text: "2022-2023", value: 2022}],
  shifts: [{text: "Первая", value: 1}, {text: "Вторая", value: 2}, { text: "Третья", value: 3 }],
  
  educationYearOptions(){
    return this.eduYears;
  },
  shiftOptions(){
    return this.shifts;
  },
  getSiftVal(shift) {
    let ret = '';
    switch (shift) {
      case 1:
        ret = 'Первая';
        break;
      case 2:
        ret = 'Вторая';
        break;
    }

    return ret;
  },  
  quarterOptions(){
    return [{text: "I", value: 1}, {text: "II", value: 2}, {text: "III", value: 3}, {text: "IV", value: 4}, {text: "Годовой", value: 5}];
  },
  preschoolLevels(){
    return [{text: "Ясельная", value: 1}, {text: "Младшая", value: 2}, {text: "Средняя", value: 3}, {text: "Старшая", value: 4}, {text: "Подготовительная", value: 5}];
  },
  preschoolLevelName(index){
    let list = {
      1: "Ясельная",
      2: "Младшая",
      3: "Средняя",
      4: "Старшая",
      5: "Подготовительная",
    };
    return list[index];
  },
  dayItems: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  dayItemsShort: [0, 1, 2, 3, 4, 5, 6, 7],
  days: [1, 2, 3, 4, 5, 6],

  startOfWeek(date) {
    let diff = date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1);
    
    return new Date(date.setDate(diff));
  },
  educationPlanAdditionalMaterialTypes(){
    return [
      {text: "Сыылка", value: 'link'},
      {text: "Изображение", value: 'image'},
      {text: "Файл", value: 'file'}];
  },
  
  monthOptions(){
    let ret = [];
    
    ret.push({text: i18n.t("studentAbsenceNumberOfDays.months.9"), value: 9});
    ret.push({text: i18n.t("studentAbsenceNumberOfDays.months.10"), value: 10});
    ret.push({text: i18n.t("studentAbsenceNumberOfDays.months.11"), value: 11});
    ret.push({text: i18n.t("studentAbsenceNumberOfDays.months.12"), value: 12});
    ret.push({text: i18n.t("studentAbsenceNumberOfDays.months.1"), value: 1});
    ret.push({text: i18n.t("studentAbsenceNumberOfDays.months.2"), value: 2});
    ret.push({text: i18n.t("studentAbsenceNumberOfDays.months.3"), value: 3});
    ret.push({text: i18n.t("studentAbsenceNumberOfDays.months.4"), value: 4});
    ret.push({text: i18n.t("studentAbsenceNumberOfDays.months.5"), value: 5});
    
    return ret;
  },
  currentMonth(){
    return (new Date()).getMonth() + 1;
  },
};

export default dictionariesService;
