<template>
  <div>
    <div class="row">
      <div class="col-xl-12 col-lg-12 order-lg-3 order-xl-1">
        <KTPortlet v-bind:title="''">
          <template v-slot:title>
            {{ $t("quarterMarks.title") }}
          </template>
          <template v-slot:toolbar>
<!--            <b-form-select
                v-model="educationYear"
                :options="educationYears"
                required
            ></b-form-select>-->
          </template>
          <template v-slot:body>
            <b-card no-body>
              <table class="table table-bordered">
                <thead class="thead-light">
                <tr>
                  <th width="5%">#</th>
                  <th width="10%" >Предметы</th>
                  <th v-for="quarter in quarters" :key="quarter">
                    {{getQuarterName(quarter)}}
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(subject, subjectIndex) in subjects" :key="subjectIndex">
                  <td>
                    {{subjectIndex + 1}}
                  </td>
                  <td>
                    {{subject.subjectName}}
                  </td>
                  <th v-for="quarter in quarters" :key="quarter">
                    {{marks[quarter][subject.subjectId]}}
                  </th>
                </tr>
                </tbody>
              </table>
              
            </b-card>
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<style>
  .nav-tabs{
    display: none;
  }

/*  table, th, td {
    border: 1px solid black;
  }*/
  .hw-title{
    font-style: italic;
  }

  .mobile-day-select{
    display: none;
  }
  
  @media (max-width: 767px) {

    #marksTabs div.col-auto{
      display: none;
    }

    .mobile-day-select{
      display: block;
    }
  }
  th.rotate {
    writing-mode: vertical-rl;
  }


</style>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";
import DictionariesService from "@/common/dictionaries.service";

export default {
  name: "studentQuarterMarks",
  components: {
    KTPortlet
  },
  data() {
    return {
      title: '',
      educationYear: DictionariesService.currentYear(),
      educationYears: DictionariesService.educationYearOptions(),
      quarters: [
          1, 2, 3, 4, 5, 6, 7
      ],
      subjects:{},
      marks: {}
    };
  },
  mounted() {
    this.reloadData();
  },
  methods: {
    reloadData(){
      let $this = this;      
      let params = {
        educationYear : this.educationYear
      }
      ApiService.postSecured("marks/getQuarterMarksForStudent", params)  
          .then(({ data }) => {
            $this.title = '';
            $this.subjects = data.subjects;
            
            let marksList = {};
            for (let i in $this.quarters) {
              let quarter = $this.quarters[i];

              marksList[quarter] = {};
            }
            
            for (let ii in data.marks){
              let marks  = data.marks[ii];
              for(let markIndex in marks.marks){
                let mark = marks.marks[markIndex];

                marksList[marks.quarter][mark.subjectId] = mark.mark;
              }
            }
            
            $this.marks = marksList;  
          })
          .catch(({ response }) => {
            console.log(response);
          });
    },
    getQuarterName(quater){
      let quaerters = {
        1: "I четверть",
        2: "II четверть",
        3: "III четверть",
        4: "IV четверть",
        5: "Годовая",
        6: "Экзаменационная",
        7: "Итоговая",
      }
      
      return quaerters[quater];
    }
  }
};
</script>
